import axios from 'axios';
import { get, find, mapKeys, camelCase } from 'lodash';
import { localCacheGet, localCacheSet } from './local-cache';

const burnNoticeSpoof = async () => {
  const DAY_DATA = {
    TODAY: {
      date: new Date().toISOString().substring(0, 10),
      label: 'Today',
      url: `https://ww2.valleyair.org/aqinfo/WebPageForecast00.json`,
    },
    TOMORROW: {
      date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
      label: 'Tomorrow',
      url: `https://ww2.valleyair.org/aqinfo/WebPageForecast01.json`,
    },
  };

  const _yTT = (date) => {
    const unknownDateStr = new Date(date).toISOString().substring(0, 10);

    const todayDate = new Date();
    const todayDateStr = todayDate.toISOString().substring(0, 10);

    const yesterdayDate = new Date(todayDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    const yesterdayDateStr = yesterdayDate.toISOString().substring(0, 10);

    const tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrowDateStr = tomorrowDate.toISOString().substring(0, 10);

    switch (unknownDateStr) {
      case yesterdayDateStr:
        return 'Yesterday';
      case todayDateStr:
        return 'Today';
      case tomorrowDateStr:
        return 'Tomorrow';
      default:
        return 'Unknown';
    }
  }

  const _processBurnStatus = (status) => {
    switch (status) {
      case 'No Burning Unless Registered':
        return 'Registered';
      case 'No Burning For All':
        return 'No';
      case 'Burning Discouraged':
        return 'Discouraged';
      default:
        return 'Unknown';
    }
  }

  const _processAiqStatus = (status) => {
    switch (status) {
      case 'No Burning Unless Registered':
        return 'Yellow';
      case 'No Burning For All':
        return 'Orange';
      case 'Burning Discouraged':
        return 'Green';
      default:
        return 'White';
    }
  }

  const _processBurnDay = async (infoObject) => {
    const cacheKey = `burnNotice-${infoObject.date}`;
    const cachedData = localCacheGet(cacheKey);
    if (cachedData) {
      console.log(`loaded burnNotice ${cacheKey} from cache`);
      return cachedData;
    }
    const axiosResponse = await _apiGet(infoObject.url);
    const dayData = get(axiosResponse, 'data', []);
    const dayDataKern = find(dayData, {
      County: 'Kern (SJV Air Basin portion)',
    });
    const fixedDataObj = mapKeys(dayDataKern, (v, k) => camelCase(k));
    const returnObj = {};
    returnObj.burnDate = new Date(fixedDataObj['dateForecastedFor'])
      .toISOString()
      .substring(0, 10);
    returnObj.burnText = _yTT(returnObj.burnDate);
    returnObj.burnStatus = _processBurnStatus(
      fixedDataObj.strCbybDeclaration,
    );
    returnObj.burnImage = `${returnObj.burnStatus}.svg`;
    returnObj.aiqText = `${fixedDataObj.value} ${fixedDataObj.status} (${
      fixedDataObj.pollutant.split(' ')[0]
    })`;
    returnObj.aiqDate = returnObj.burnDate;
    returnObj.aiqStatus = _processAiqStatus(
      fixedDataObj.strCbybDeclaration,
    );

    localCacheSet(cacheKey, returnObj, 60 * 60 * 1000);
    return returnObj;
  }

  const burnInfo = await Promise.all([
    _processBurnDay(DAY_DATA.TODAY),
    _processBurnDay(DAY_DATA.TOMORROW),
  ]);
  return { success: true, data: burnInfo };
}

const tideForcastSpoof = async (path) => {
  let startDate = path.split('=')[1].split('&')[0];
  let endDate = path.split('=')[2];
  const cacheKey = `tideForcast-${startDate}-${endDate}`;
  const cachedData = localCacheGet(cacheKey);
  if (cachedData) {
    console.log(`loaded tideForcast ${cacheKey} from cache`);
    return cachedData;
  }
  startDate = startDate.replace(/-/g, '');
  endDate = endDate.replace(/-/g, '');
  const response = await _apiGet(`https://tidesandcurrents.noaa.gov/api/datagetter?product=predictions&application=NOS.COOPS.TAC.WL&begin_date=${startDate}&end_date=${endDate}&datum=MLLW&station=9412110&time_zone=lst_ldt&units=english&interval=hilo&format=json`);
  const { predictions } = response.data;
    const keyConverstion = {
      t: 'time',
      v: 'height',
      type: 'highOrLow',
    };
    predictions.forEach((prediction) => {
      Object.keys(prediction).forEach((key) => {
        const newKey = keyConverstion[key];
        prediction[newKey] = prediction[key];
        if (newKey !== key) {
          delete prediction[key];
        }
      });
    });
    localCacheSet(cacheKey, { success: true, data: predictions }, 60 * 60 * 1000);
    return { success: true, data: predictions };
}

const _apiGet = async (path) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`, {
      headers: {
        'X-Requested-With': 'kaseyisawesome.com',
        'Referrer': 'kaseyisawesome.com',
      }
    });
    return response;
  } catch (err) {
    console.log('err ', err);
    throw err; // Rethrow the error to propagate it up the stack
  }
}

export { burnNoticeSpoof, tideForcastSpoof }