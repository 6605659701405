import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Row, Col, Table, InputGroup } from 'react-bootstrap';
import LoadingIcon from '../../helpers/LoadingIcon'
import ErrorIcon from '../../helpers/ErrorIcon'
import { DateTime } from "luxon";
import { apiGetSpoof } from '../../helpers/Api';

import './tideForcast.css'

export const TideForcast = () => {

  const [ dates, setDates ] = useState({startDate: new Date(), endDate: new Date()})
  const [ predictions, setPredictions ] = useState([])
  const [ error, setError ] = useState('')
  const [ state, setState ] = useState('INIT')

  useEffect(()=> {
    const getForcast = async () => {
      try {
        setError('')
        setState("LOADING")
        const startDate = DateTime.fromJSDate(dates.startDate).toISODate()
        const endDate = DateTime.fromJSDate(dates.endDate).toISODate()
        const response = await apiGetSpoof(`/api/tide-forcast?startDate=${startDate}&endDate=${endDate}`)
        if (response.success) {
          setPredictions(response.data)
          setState("LOADED")
        } else {
          setError(response.data)
          setState("ERROR")
        }
      } catch (err) {
        setError(err.toString())
        setState("ERROR")
      }
    }
    getForcast()
  }, [dates])

  const handleStartDateChange = (date) => {
    setError('')
    if (date > dates.endDate) {
      setDates({startDate: date, endDate: date})
      setPredictions([])
    } else {
      setDates({...dates, startDate: date})
      setPredictions([])
    }
  }

  const handleEndDateChange = (date) => {
    setError('')
    if (dates.startDate > date) {
      setDates({startDate: date, endDate: date})
      setPredictions([])
    } else {
      setDates({...dates, endDate: date})
      setPredictions([])
    }
  }

  const display = () => {
    switch(state) {
      case "LOADING":
        return (<tr><td colSpan={3} className="text-center"><LoadingIcon style={{marginTop: '1em', marginBottom: '1em'}}/></td></tr>)
      case "LOADED":
        return (
          predictions.map((perdiction, index) => {
            return (
              <tr key={index}>
                <td style={{textAlign:'left'}}>{DateTime.fromFormat(perdiction.time, 'yyyy-MM-dd HH:mm').toFormat('MM-dd h:mm a') }</td>
                <td style={{textAlign:'center'}}>{perdiction.height} ft</td>
              </tr>
            )
          })
        )
      case "ERROR":
        return (<tr><td colSpan={3} className="text-center"><ErrorIcon errorString={error}/></td></tr>)
      default:
        return (<tr><td colSpan={3} className="text-center"></td></tr>)
    }
  }
  
  return (
    <Card body style={{ opacity:'0.8', padding: '0', marginBottom:'1em', zIndex:'1' }}>
      <Card.Body style={{ padding: '0 1em 0 1em' }}>
        <Card.Title style={{backgroundColor:'#FFF'}} className='text-center'><h2>Pismo Tides</h2></Card.Title>
          <div style={{marginBottom:'0.5em'}}>
            <InputGroup style={{flexWrap: 'nowrap'}} className="mb-2">
              <InputGroup.Text id="basic-addon1">Start Date</InputGroup.Text>
              <DatePicker className="form-control react-datepicker-input" selected={dates.startDate} onChange={(event) => handleStartDateChange(event)}/>
            </InputGroup>
            <InputGroup style={{flexWrap: 'nowrap'}}>
              <InputGroup.Text id="basic-addon1">End Date&nbsp;&nbsp;</InputGroup.Text>
              <DatePicker className="form-control react-datepicker-input" selected={dates.endDate} onChange={(event) => handleEndDateChange(event)}/>
            </InputGroup>
          </div>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Date</th>
                    <th style={{textAlign:'center'}}>Predicted</th>
                  </tr>
                </thead>
                <tbody>
                  {display()}
                </tbody>
              </Table>
            </Col>
          </Row>
      </Card.Body>
    </Card>
  )
}
