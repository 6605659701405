import React, { useState, useEffect } from 'react'
import { Col, Row, Card, CardImg } from 'react-bootstrap'
import { get } from 'lodash'
import LoadingIcon from '../../helpers/LoadingIcon'
import ErrorIcon from '../../helpers/ErrorIcon'
import { apiGetSpoof } from '../../helpers/Api';
import './burnNotice.css'

export const BurnNotice = () => {

  const [ burnDays, setBurnDays ] = useState([])
  const [ error, setError ] = useState('')
  const [ state, setState ] = useState('INIT')

  useEffect(()=> {
    const getBurnNotices = async () => {
      try {
        if (state !== 'INIT') return
        setError('')
        setState("LOADING")
        const response = await apiGetSpoof('/api/burn-notices')
        if (response.success) {
          setBurnDays(response.data)
          setState("LOADED")
        } else {
          setError(response.data)
          setState("ERROR")
        }
      } catch (err) {
        setState("ERROR")
        setError(err.toString())
      }
    }
    getBurnNotices()
  }, [])

  const burnSection = (obj) => {
    return (
      <div>
        <div className="text-center" style={{color: 'white'}}>
          {obj['burnText']}
        </div>
        <CardImg width="100%" src={obj['burnImage']} alt="Registered"/>
        <div className="text-center">
          <span style={{color:`${obj['aiqStatus']}`}}>
            {obj['aiqText']}
          </span>
        </div>
      </div>
    )
  }

  const display = () => {
    switch (state) {
      case "LOADING":
        return <LoadingIcon className="text-white" style={{marginTop: '2em'}}/>
      case "LOADED":
        return (
        <Row>
          {
            burnDays.map((burnDay) => {
              return (
                <Col key={burnDay.burnDate}>{burnSection(burnDay)}</Col>
              )
            })
          }
        </Row>)
      case "ERROR":
        return <ErrorIcon errorString={error}/>
      default: 
        return <div></div>
    }
  }

  return (
    <Card className="mb-2" body inverse="true" style={{ opacity:'0.8', backgroundColor: '#333', borderColor: '#333', padding: '0', marginBottom:'1em' }}>
      <Card.Body style={{ padding: '0 1em 1em 1em' }}>
        <Card.Title style={{color: 'white'}} className='text-center'>
          <h3>Burn Notice</h3>
        </Card.Title>
        {display()}
      </Card.Body>
    </Card>
  )
}
