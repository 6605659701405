import React from 'react';

const Error = ({ errorString, className, style }) => {

  return (
    <div className={`text-center text-danger ${className}`} style={style}>
      <span>{errorString || 'Error'}</span>
    </div>
  )
}

export default Error;