import axios from 'axios';
import { burnNoticeSpoof, tideForcastSpoof } from './backend-spoof';

const TOKEN_KEY = 'token';

const _getToken = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  if (token === undefined || token === null || token === 'undefined') {
    const queryStr = window.location.search;
    const paramsArray = queryStr.replace('?','').split('&').map(m=>m.split('='));
    const paramsObj = paramsArray.reduce((accumulator, currentValue) => { 
      accumulator[currentValue[0]] = currentValue[1]
      return accumulator
    } ,{})
    token = paramsObj.token;
    if (token !== undefined && token !== null) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
  }
  return token; 
}

const _getOptions = () => {
  const options = { headers: {} };

  const token = _getToken();
  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`
  }
  return options;
}


const apiGet = (path) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${path}`, _getOptions());
}

const apiGetSpoof = async (path) => {
  try {
    if (path === '/api/burn-notices') {
      return burnNoticeSpoof();
    } else if (/\/api\/tide-forcast/.test(path)) {
      return tideForcastSpoof(path);
    } else {
      return new Error('Unknown path');
    }
  } catch (err) {
    return { success: false, data: err.toString() }
  }
}


export { apiGet, apiGetSpoof }