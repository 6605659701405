import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const LoadingIcon = ({className, style}) => {

  return (
    <div className={`text-center ${className}`} style={style}>
      <Spinner animation="border" role="status">
      </Spinner>
    </div>
  )
}

export default LoadingIcon;