const LOCAL_CACHE_DISABLED = process.env.REACT_APP_LOCAL_CACHE_DISABLED || 'false';

function localCacheSet(key, value, timeout) {

  if (LOCAL_CACHE_DISABLED === 'true') {
    return null;
  }

  const creationTime = new Date().getTime();
  const expirationTime = timeout === 0 ? null : creationTime + timeout;  

  const cacheItem = {
    value,
    creationTime,
    expirationTime
  };

  localStorage[key] = JSON.stringify(cacheItem);

  return { value, expirationTime }
}

function localCacheGet(key) {

  if (LOCAL_CACHE_DISABLED === 'true') {
    return null;
  }

  const cacheItem = localStorage[key];

  if (cacheItem) {
    const currentTime = new Date().getTime();

    const parsedCacheItem = JSON.parse(cacheItem);
    if (parsedCacheItem.expirationTime > currentTime) {
      try {
        return JSON.parse(parsedCacheItem.value);
      } catch (e) {
        return parsedCacheItem.value;
      }
    } else {
      delete localStorage[key];
    }
  }

  return null;
}

module.exports = { localCacheSet, localCacheGet };