import React from 'react';
import { BurnNotice, TideForcast, WeatherForcast } from './components'
import { Container, Col, Row } from 'react-bootstrap';

const App = (props) => {
    return (
      <Container fluid={true} style={{marginTop:'1em'}}>
        <Row>
          <Col md='3'>
              <BurnNotice/>
              <TideForcast/>
            </Col>
          <Col md='3'>
            {/* <WeatherForcast title='Bakersfield' location='93312'/> */}
          </Col>
          <Col md='3'>
            {/* <WeatherForcast location='Kern River'/> */}
          </Col>
          <Col md='3'>
            {/* <WeatherForcast location='Pismo'/> */}
          </Col>
        </Row>
        </Container>
    )
}

export default App;